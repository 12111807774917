<template>
  <v-main>
    <validation-observer ref="form">
      <v-row gutters="2" class="pt-0 pb-0" justify="end">
        <v-col cols="12" md="12" class="text-right">
          <Buttons
            :textCancel="$t('GENERAL.CANCEL')"
            v-on:cancel="cancel"
            :textSave="$t('GENERAL.SAVE')"
            v-on:save="saveData"
          />
        </v-col>
      </v-row>
      <v-row gutters="2" class="pt-0 pb-0">
        <v-col cols="12" md="8" class="pt-0 pb-0 mb-2">
          <PackagesForm
            ref="packagesForm"
            :form-data.sync="formData"
          ></PackagesForm>
        </v-col>
        <!-- Additional Info -->
        <v-col cols="12" md="4" class="pt-0 pb-0">
          <v-card class="mb-2">
            <v-card-title>{{ $t("GENERAL.VISIBILITY") }}</v-card-title>
            <v-card-text>
              <Visibility :input.sync="isReady"></Visibility>
            </v-card-text>
          </v-card>
          <v-card class="mb-2">
            <v-card-title>
              {{ $t("PACKAGES.FORM.MARKETS_COUNTRY_SELECTOR_TITLE") }}
            </v-card-title>
            <v-card-text>
              <MarketsCountriesSelector
                :input.sync="eligibleBy"
                is-required
              ></MarketsCountriesSelector>
            </v-card-text>
          </v-card>
          <Translation
            ref="translationComponent"
            :fields="translations"
            model="product"
            skeleton="3"
          ></Translation>
        </v-col>
      </v-row>
    </validation-observer>
  </v-main>
</template>

<script>
import { mapGetters } from "vuex";
import { ValidationObserver } from "vee-validate";

import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { STORE_PRODUCT_PACKAGE } from "@/core/services/store/packages/product_package.module";

import Buttons from "@/view/content/components/Buttons";
import Visibility from "@/view/content/components/forms/Visibility";
import PackagesForm from "@/view/pages/packages/components/Form";
import MarketsCountriesSelector from "@/view/content/components/MarketsCountriesSelector";
import Translation from "@/view/content/components/translation/Index";

export default {
  name: "packages-new",
  components: {
    Buttons,
    ValidationObserver,
    Visibility,
    PackagesForm,
    MarketsCountriesSelector,
    Translation,
  },
  data() {
    return {
      formData: {},
      eligibleBy: {},
      isReady: null,
      translations: [
        {
          title: this.$i18n.t("GENERAL.NAME"),
          type: "name",
        },
        {
          title: this.$i18n.t("GENERAL.DESCRIPTION"),
          type: "description",
        },
      ],
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("PACKAGES.TITLE"), route: "/packages" },
      { title: this.$t("PACKAGES.NEW") },
    ]);
  },
  computed: {
    ...mapGetters({
      campus: "campus",
    }),
    ...mapGetters(["message"]),
  },
  methods: {
    async saveData() {
      const globalValidation = await this.$refs.form.validate();
      const validationPackageForm = await this.$refs.packagesForm.validateForm();
      const validate = globalValidation && validationPackageForm;
      const hasOptions = this.formData.hasOwnProperty("packageOptions")
        ? this.formData.packageOptions
        : [];
      if (validate && hasOptions.length) {
        const response = await this.$store.dispatch(
          STORE_PRODUCT_PACKAGE,
          this.formatData(this.formData)
        );
        if (response) {
          this.$bvToast.toast(
            this.$t("PACKAGES.MESSAGE.SUCCESS_STORAGE").toString(),
            {
              title: this.$t("MESSAGE.SUCCESS.TITLE_NEW").toString(),
              variant: "success",
              solid: true,
            }
          );

          setTimeout(() => {
            this.$router.push({
              name: "packages-edit",
              params: { id: response.productId },
            });
          }, 1300);
        }
      } else {
        this.$bvToast.toast(
          this.$t("GENERAL.PLEASE_VERIFY_FIELDS").toString(),
          {
            title: "Error",
            variant: "danger",
            solid: true,
          }
        );
      }
    },
    async cancel() {
      await this.$router.push({
        name: "packages",
      });
    },
    formatData(data) {
      const productTypeId = 6;
      const { campusId } = this.campus;
      const {
        name,
        description,
        packageDurationType,
        packageOptions,
        startDateTemplate,
        startDateType,
      } = data;
      const {
        availabilityStart,
        availabilityEnd,
        reqMinDurationTypeId,
        reqMinDurationAmount,
        reqMaxDurationAmount,
      } = data.weekly;
      const productEditions = data.ranges?.map(range => ({
        start: range.from,
        end: range.to,
        amount: range.amount,
      }));
      const productPackageOption = packageOptions?.map(option => {
        const courses = option.packageOptionItems.coursesSelected?.map(
          course => ({
            productId: course.id,
            quantity: null,
            durationTypeId: null,
            durationAmount: null,
          })
        );
        const rooms = option.packageOptionItems.roomsSelected?.map(room => ({
          productId: room.id,
          quantity: null,
          durationTypeId: option.packageOptionItems.durationTypeId
            ? option.packageOptionItems.durationTypeId
            : null,
          durationAmount: option.packageOptionItems.durationAmount
            ? option.packageOptionItems.durationAmount
            : null,
        }));
        const services = option.packageOptionItems.servicesSelected?.map(
          service => ({
            ...service,
            productId: service.productId,
            quantity: service.quantity,
            durationTypeId: null,
            durationAmount: null,
          })
        );
        const productPackageOptionFeeItems = option.packageOptionItems.feesSelected?.map(
          fee => ({
            feeId: fee.id,
          })
        );
        const productPackageOptionItems = [...courses, ...rooms, ...services];
        return {
          name: option.name,
          productPricingTemplateId: option.productPricingTemplateId,
          productPackageOptionItems,
          productPackageOptionFeeItems,
        };
      });
      const product = {
        ...this.eligibleBy,
        isReady: this.isReady,
        name,
        description,
        campusId,
        productTypeId,
        availabilityStart,
        availabilityEnd,
      };

      return {
        reqMinDurationAmount,
        reqMaxDurationAmount,
        packageDurationType,
        reqMinDurationTypeId,
        product,
        productEditions,
        productPackageOption,
        startDateType,
        startDateTemplate,
      };
    },
  },
};
</script>
